import { get } from 'lodash'
import React from 'react'
import { connect, useSelector } from 'react-redux'
import FontAwesome from 'react-fontawesome'
import Button from 'react-bootstrap/lib/Button'

import { Paths } from 'utils/paths'
import Navigation from 'utils/navigation'
import { currentCheckin, currentCheckinBoard } from 'selectors/wprsnpr'
import { usernameSelector } from 'selectors/users'
import GroupActions from 'actions/groups'
import DataActions from 'actions/data'
import WprSnprActions from 'actions/wprsnpr'
import Err from 'components/Elements/Err'
import SimpleModal from 'components/Elements/SimpleModal'
import BoardImage from 'components/Elements/BoardImage'
import SemverIndicator from 'components/Elements/SemverIndicator'
import { confirmation } from 'components/Elements/ConfirmModal'
import { userSelector } from 'selectors/users'


class WipperSnapperForm extends React.PureComponent {
  state = {
    errors: []
  }

  input = React.createRef()

  componentDidMount() {
    this.props.subscribeToWprsnpr()
  }

  createNewWipper = (event) => {
    event.preventDefault()

    const { hideModal, createGroup, username, machineName, deviceId, semver } = this.props

    createGroup({
      machine_name: machineName,
      name: this.input.current.value,
      key: deviceId,
      wipper_semver: semver,
      description: "A WipperSnapper Device"
    })

    .then(hideModal)

    .then(() => Navigation.go(Paths.device({ username, key: deviceId })))

    .catch(errorResponse => {
      if(errorResponse.obj?.error?.length > 0) {
        const errors = errorResponse.obj.error

        this.setState({ errors })
      } else {
        // Don't know this error, keep it alive.
        throw errorResponse
      }
    })
  }

  warnHideModal = () => {
    const areYouSure = <>
      <p>Are you sure you want to close this window <strong>without</strong> saving this WipperSnapper device?</p>
      <p>(If you close this window without clicking 'Continue to Device Page', you will need to disconnect and reconnect the device to create it.)</p>
    </>

    confirmation({
      message: areYouSure,
      onConfirm: this.props.hideModal
    })
  }

  resetErrors = () => this.setState({ errors: [] })

  semverUpdateClicked = () => {
    const { username, machineName: boardName, hideModal } = this.props

    hideModal()
    Navigation.go(Paths.new_device_board({ username, boardName }))
  }

  render() {
    const { semver, showModal, machineName, boardDisplayName } = this.props

    return (
      <SimpleModal
        title="New Device Detected!"
        show={ showModal }
        onHide={ this.warnHideModal }
      >
        <div className="row">
          <div className="col-xs-12">
            <p>You have successfully connected a new <strong>{ boardDisplayName || machineName }</strong> device to Adafruit IO. It is already set up and submitting data. You can name the device here, and set up components on the device page.</p>
          </div>

          <div className="col-xs-12" style={{ minHeight: '200px', textAlign: 'center' }}>
            <BoardImage machineName={ machineName }/>
          </div>

          <div className="col-xs-12">
            <form onSubmit={ this.createNewWipper }>
              <Err errors={ this.state.errors } reset={ this.resetErrors } />

              <p>
                <label htmlFor="new-device-name">Device Name</label>
                <input id="new-device-name" type="text" ref={ this.input } defaultValue={ boardDisplayName }/>
              </p>

              <p>
                <label>Firmware Version:</label> <SemverIndicator semver={ semver } onUpdateClicked={ this.semverUpdateClicked } />
              </p>

              <Button bsStyle='primary' bsSize="large" block onClick={ this.createNewWipper }>
                Continue to Device Page <FontAwesome name='chevron-right' />
              </Button>
            </form>
          </div>
        </div>
      </SimpleModal>
    )
  }
}

const
  mapState = state => ({
    username: usernameSelector(state),
    showModal: !!get(state, 'wprsnpr.showPopover'),
    machineName: currentCheckin(state).machineName,
    deviceId: currentCheckin(state).deviceId,
    semver: currentCheckin(state).semver,
    boardDisplayName: currentCheckinBoard(state).displayName
  }),

  mapDispatch = dispatch => ({
    hideModal: () => dispatch(WprSnprActions.wprsnprHidePopover()),
    createGroup: group => dispatch(GroupActions.create({ group })),
    subscribeToWprsnpr: username => {
      dispatch(DataActions.subscribeToWprsnprStatus(username))
      dispatch(DataActions.subscribeToWprsnprConnections(username))
    }
  }),

  mergeProps = (mapState, mapDispatch) => ({
    ...mapState,
    ...mapDispatch,
    subscribeToWprsnpr: () => mapDispatch.subscribeToWprsnpr(mapState.username),
  })


const ConnectedWipperSnapperForm = connect(mapState, mapDispatch, mergeProps)(WipperSnapperForm)

// tiny, functional component wrapper stops the form rendering before user/client is ready
// replace when whole component becomes functional
const FormWrapper = () => useSelector(userSelector) && <ConnectedWipperSnapperForm />

export default FormWrapper
