export const FeedbackTypes = {
  SERVICE: 'service_issue',
  TROUBLE_VIEWING: 'trouble_viewing',
  WIPPERSNAPPER: 'wippersnapper_question',
  WIPPER_SUGGESTION: 'wippersnapper_suggestion',
  PLUS: 'plus_question',
  BETA: 'beta_request',
  SUGGESTION: 'suggestion',
  PROJECT: 'project_help',
  GENERAL: 'general_question',
  OTHER: 'other'
}

export default FeedbackTypes
