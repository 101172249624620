import IconList from 'views/Landing/icons-faq.json'
import { propCheck } from 'utils/properties'
import { ioNameToHex } from 'utils/colors'

const ICON_SEARCH = IconList.Favicons.concat(
  IconList.Weather.map(name => "w:" + name)
).sort().map(value => {
  return { id: value, text: value }
})

function option(lv) {
  const [ label, value ] = lv
  return { label, value }
}

// label, name, inputType, defaultValue
function property(options) {
  return Object.assign({}, options, {
    key: `property_${options.name}`,
    htmlName: `property[${options.name}]`,
    selectOptions: (options.select || []).map(option)
  })
}

const BlockTypes = {
  "rich_text": {
    "max_feeds": 5,
    "min_size": [1, 1],
    "default_size": [2, 2],
    "max_size": [8, 8],
    "aspect_ratio": "dynamic",
    "description": "A custom rich text block.",
    "properties": [
      property({label: 'Template', name: 'text', inputType: 'template', defaultValue: '{{feeds.key.value}}'}),
    ],
    "descriptor": "Rich Text"
  },
  "divider": {
    "max_feeds": 0,
    "min_size": [1, 1],
    "default_size": [1, 2],
    "max_size": [10, 10],
    "aspect_ratio": "dynamic",
    "description": "A horizontal or vertical dividing line for dashboard organization.",
    "properties": [
      property({label: "Line Width", name: 'lineWidth', inputType: 'select', defaultValue: '2', select: [['1px', '1'], ['2px', '2'], ['3px', '3'],['4px', '4'], ['5px', '5'], ['6px', '6'], ['7px', '7'], ['8px', '8']] }),
    ],
    "descriptor": "Dividing Line"
  },
  "icon": {
    "max_feeds": 1,
    "min_size": [1, 1],
    "default_size": [2, 2],
    "max_size": [8, 8],
    "aspect_ratio": "square",
    "description": "A visible indicator of feed state using custom icons. <a target='_blank' rel='noopener' href='/icons-faq'>Click here</a> to search for valid icon types. <br/><br/>Send the icon name to the selected feed to display the named icon inside the block or select the <strong>Static Icon</strong> option and select from the list of icons.",
    "properties": [
      property({label: 'Color', name: 'fontColor', inputType: 'color', defaultValue: ioNameToHex('blue') }),
      property({label: 'Static Icon', name: 'static', inputType: 'checkbox', defaultValue: false, hint: "When checked, ignore feed value and use the selected 'Static Icon Value' icon all the time."}),
      property({label: 'Static Icon Value', name: 'value', inputType: 'search', toggledBy: 'static', data: ICON_SEARCH, hint: "When 'Static Icon' is checked, use this icon." }),
    ],
    "descriptor": "Icon"
  },
  "indicator": {
    "max_feeds": 1,
    "min_size": [2, 1],
    "default_size": [4, 1],
    "max_size": [8, 8],
    "aspect_ratio": "dynamic",
    "description": "A simple on/off indicator lamp. Feed values are compared using the given conditions. If the conditions are true, then \"On Color\" is used, if false, \"Off Color\". All values are assumed to be numeric for comparison. If the current feed value can't be converted to a number, it will be treated as a string.",
    "properties": [
      property({label: 'On Color', name: 'onColor', inputType: 'color', defaultValue: ioNameToHex('green') }),
      property({label: 'Off Color', name: 'offColor', inputType: 'color', defaultValue: ioNameToHex('red') }),
      property({label: "Conditions", name: 'conditions', inputType: 'conditions', defaultValue: '[["<", "2.0"]]'}),
    ],
    "descriptor": "Indicator",
    "hide_name": true
  },
  "battery": {
    "max_feeds": 1,
    "min_size": [2, 1],
    "default_size": [2, 1],
    "max_size": [4, 2],
    "aspect_ratio": "fixed",
    "description": "A simple battery level gauge. Feed values are compared using the given conditions. If one of the conditions is true, than that condition will be used for the color to be displayed. All values are assumed to be numeric for comparison and between 0-100.0. If the current feed value can't be converted to a number, it will be treated as a string.",
    "properties": [
      property({label: 'Show Feed Percentage', name: 'showNumbers', inputType: 'checkbox', defaultValue: false, hint: "When checked, show the value of the feed as a percent."}),
      property({label: 'High Color', name: 'highColor', inputType: 'color', defaultValue: ioNameToHex('green') }),
      property({label: 'Medium Color', name: 'mediumColor', inputType: 'color', defaultValue: ioNameToHex('yellow') }),
      property({label: 'Low Color', name: 'lowColor', inputType: 'color', defaultValue: ioNameToHex('red') }),
      property({label: "Medium Condition", name: 'mediumConditions', inputType: 'conditions', defaultValue: '[["<", "30.0"]]'}),
      property({label: "Low Condition", name: 'lowConditions', inputType: 'conditions', defaultValue: '[["<", "2.0"]]'}),
    ],
    "descriptor": "Battery Gauge",
    "hide_name": true
  },
  "momentary_button": {
    "max_feeds": 1,
    "default_size": [2, 2],
    "min_size": [2, 2],
    "max_size": [8, 8],
    "aspect_ratio": "dynamic",
    "description": "A momentary button works similarly to a hardware push button.",
    "properties": [
      property({label: 'Button Text', name: 'text', inputType: 'text', defaultValue: 'Reset'}),
      property({label: 'Press Value', name: 'value', inputType: 'text', defaultValue: '1' }),
      property({label: 'Release Value', name: 'release', inputType: 'text', defaultValue: '0', hint: "Leave this field blank to not send anything when the button is released." }),
      property({label: 'Light Mode Color', name: 'backgroundColorLight', inputType: 'color', defaultValue: ioNameToHex('blue') }),
      property({label: 'Dark Mode Color', name: 'backgroundColor', inputType: 'color', defaultValue: ioNameToHex('green-alt') }),
    ],
    "publish": true,
    "descriptor": "Momentary Button"
  },
  "remote_control": {
    "max_feeds": 1,
    "min_size": [3, 7],
    "default_size": [3, 7],
    "max_size": [6, 10],
    "aspect_ratio": "dynamic",
    "description": "A Mini Remote Control for your dashboard! Based on the <a target='_blank' rel='noopener' href='https://www.adafruit.com/product/389'>Mini Remote Control sold by Adafruit</a>, mimics the output of the <a target='_blank' rel='noopener' href='https://github.com/adafruit/Adafruit-NEC-remote-control-library'>Adafruit NEC remote control library</a>.",
    "properties": [
      // property({label: "Show Numbers?", name: 'showNumbers', inputType: 'select', defaultValue: 'yes', select: [['Yes', 'yes'], ['No', 'no']] }),
    ],
    "publish": true,
    "descriptor": "Remote Control"
  },
  "number_pad": {
    "max_feeds": 1,
    "min_size": [2, 4],
    "default_size": [2, 4],
    "max_size": [6, 10],
    "aspect_ratio": "dynamic",
    "description": "A telephone style number pad for your dashboard. Based on the <a target='_blank' rel='noopener' href='https://www.adafruit.com/product/419'>Membrane 3x4 Matrix Keypad sold by Adafruit</a>, produces numbers or symbols when clicked.",
    "properties": [
      // property({label: "Show Numbers?", name: 'showNumbers', inputType: 'select', defaultValue: 'yes', select: [['Yes', 'yes'], ['No', 'no']] }),
    ],
    "publish": true,
    "descriptor": "Number Pad"
  },
  "toggle_button": {
    "max_feeds": 1,
    "min_size": [2, 1],
    "default_size": [4, 2],
    "max_size": [16, 8],
    "aspect_ratio": "dynamic",
    "description": "A toggle button is useful if you have an ON or OFF type of state. You can configure what values are sent on press and release.",
    "properties": [
      property({label: "Button On Text", name: 'onText', inputType: 'text', defaultValue: 'ON', limit: 6, hint: "Limit of 6 characters for the toggle text. Use the block title to be more descriptive." }),
      property({label: "Button On Value (uses On Text if blank)", name: 'onValue', inputType: 'text', defaultValue: null }),
      property({label: "Button Off Text", name: 'offText', inputType: 'text', defaultValue: 'OFF', limit: 6, hint: "Limit of 6 characters for the toggle text. Use the block title to be more descriptive." }),
      property({label: "Button Off Value (uses Off Text if blank)", name: 'offValue', inputType: 'text', defaultValue: null }),
    ],
    "publish": true,
    "descriptor": "Toggle"
  },
  "slider": {
    "max_feeds": 1,
    "min_size": [4, 2],
    "default_size": [4, 2],
    "max_size": [16, 8],
    "aspect_ratio": "fixed",
    "description": "The slider works well if you have a range of values you need to send.",
    "properties": [
      property({label: "Slider Min Value", name: 'min'  , inputType: 'text', defaultValue: '0' }),
      property({label: "Slider Max Value", name: 'max'  , inputType: 'text', defaultValue: '100' }),
      property({label: "Slider Step Size", name: 'step' , inputType: 'text', defaultValue: '10' }),
      property({label: "Slider Label", name: 'label', inputType: 'text', defaultValue: '' }),
      property({label: "Decimal Places", name: 'decimalPlaces', inputType: 'number', defaultValue: '4', hint: 'Number of decimal places to display, defaults to 4.' }),
      //property("Slider Orientation", 'orientation', 'select', [['HORIZONTAL', 'horizontal'], ['VERTICAL', 'vertical']]),
      //property("Slider Handle", 'handle', 'select', [['ROUND', 'round'], ['BAR', 'bar']]),
    ],
    "publish": true,
    "descriptor": "Slider"
  },
  "gauge": {
    "max_feeds": 1,
    "min_size": [3, 3],
    "default_size": [4, 4],
    "max_size": [8, 8],
    "aspect_ratio": "fixed",
    "description": "A gauge is a read only block type that shows a fixed range of values.",
    "properties": [
      property({label: "Gauge Min Value", name: 'minValue', inputType: 'text', defaultValue: '0' }),
      property({label: "Gauge Max Value", name: 'maxValue', inputType: 'text', defaultValue: '100' }),
      property({label: "Gauge Width", name: 'ringWidth', inputType: 'select', defaultValue: '25', select: [['10px', '10'], ['25px', '25'], ['50px', '50'], ['75px', '75']] }),
      property({label: "Gauge Label", name: 'label', inputType: 'text', defaultValue: 'Value' }),
      property({label: "Low Warning Value", name: 'minWarning', inputType: 'text', defaultValue: '', hint: "Optional. If no low warning value is given, the gauge will only change color when the value is out of bounds." }),
      property({label: "High Warning Value", name: 'maxWarning', inputType: 'text', defaultValue: '', hint: "Optional. If no high warning value is given, the gauge will only change color when the value is out of bounds." }),
      property({label: "Decimal Places", name: 'decimalPlaces', inputType: 'number', defaultValue: '2', hint: 'Number of decimal places to display when value is a number. Defaults to 2.' }),
      property({label: 'Show Icon', name: 'showIcon', inputType: 'checkbox', defaultValue: false, hint: "When checked, show an icon with the value."}),
      property({label: 'Icon', name: 'icon', inputType: 'search', toggledBy: 'showIcon', data: ICON_SEARCH, hint: "Show this icon next to the value." }),
      //property("Gauge Min Angle", 'minAngle', 'text', minAngle),
      //property("Gauge Max Angle", 'maxAngle', 'text', maxAngle),
    ],
    "publish": false,
    "descriptor": "Gauge"
  },
  "line_gauge": {
    "max_feeds": 1,
    "min_size": [1, 1],
    "default_size": [1, 4],
    "max_size": [8, 8],
    "aspect_ratio": "dynamic",
    "description": "A line gauge is a read only block type that shows a fixed range of values.",
    "properties": [
      property({label: "Gauge Min Value", name: 'minValue', inputType: 'text', defaultValue: '0' }),
      property({label: "Gauge Max Value", name: 'maxValue', inputType: 'text', defaultValue: '100' }),
      property({label: 'Show Feed Percentage', name: 'showNumbers', inputType: 'checkbox', defaultValue: false, hint: "When checked, show the value of the feed as a percent."}),
      property({label: 'High Color', name: 'highColor', inputType: 'color', defaultValue: ioNameToHex('green') }),
      property({label: 'Medium Color', name: 'mediumColor', inputType: 'color', defaultValue: ioNameToHex('yellow') }),
      property({label: 'Low Color', name: 'lowColor', inputType: 'color', defaultValue: ioNameToHex('red') }),
      property({label: "Medium Condition", name: 'mediumConditions', inputType: 'conditions', defaultValue: '[["<", "30.0"]]'}),
      property({label: "Low Condition", name: 'lowConditions', inputType: 'conditions', defaultValue: '[["<", "2.0"]]'}),
      property({label: "Decimal Places", name: 'decimalPlaces', inputType: 'number', defaultValue: '2', hint: 'Number of decimal places to display when value is a number. Defaults to 2.' }),
      property({label: 'Low Value Icon', name: 'lowIcon', inputType: 'search', data: ICON_SEARCH, hint: "Show this icon next to the low value." }),
      property({label: 'High Value Icon', name: 'highIcon', inputType: 'search', data: ICON_SEARCH, hint: "Show this icon next to the high value." }),
      //property("Gauge Min Angle", 'minAngle', 'text', minAngle),
      //property("Gauge Max Angle", 'maxAngle', 'text', maxAngle),
    ],
    "publish": false,
    "descriptor": "Line Gauge"
  },
  "text": {
    "max_feeds": 1,
    "min_size": [2, 1],
    "default_size": [2, 1],
    "max_size": [16, 16],
    "aspect_ratio": "dynamic",
    "description": "A text block can be used to send data as well as view data. To publish, click on the text block, enter any text, and press enter to send.",
    "properties": [
      property({label: "Font Size", name: 'fontSize', inputType: 'select', defaultValue: '12',
                select: [['Small', '12'], ['Medium', '24'], ['Large', '48'], ['Big', '72'], ['Huge', '128']] }),
      property({label: 'Static Text', name: 'static', inputType: 'checkbox', defaultValue: false, hint: "When checked, ignore feed value and show the selected 'Static Text Value' all the time."}),
      property({label: 'Static Text Value', name: 'value', inputType: 'text', limit: 256, toggledBy: 'static', hint: "When 'Static Text' is checked, use this value. Limited to 256 characters." }),
      property({label: 'Decimal Places', name: 'decimalPlaces', inputType: 'number', defaultValue: '-1', hint: 'Number of decimal places to display when value is a number. Defaults to -1 (unlimited).' }),
      property({label: 'Show Icon', name: 'showIcon', inputType: 'checkbox', defaultValue: false, hint: "When checked, show an icon with the value."}),
      property({label: 'Icon', name: 'icon', inputType: 'search', toggledBy: 'showIcon', data: ICON_SEARCH, hint: "Show this icon next to the value." }),
    ],
    "publish": true,
    "descriptor": "Text"
  },
  "multiline_text": {
    "max_feeds": 1,
    "min_size": [2, 1],
    "default_size": [2, 2],
    "max_size": [16, 16],
    "aspect_ratio": "dynamic",
    "description": "A multi-line text block can be used to view larger blobs of text from a feed.",
    "properties": [
      property({label: "Font Size", name: 'fontSize', inputType: 'select', defaultValue: '12',
                select: [['Small', '12'], ['Medium', '24'], ['Large', '48'], ['Big', '72'], ['Huge', '128']] }),
      property({label: 'Static Text', name: 'static', inputType: 'checkbox', defaultValue: false, hint: "When checked, ignore feed value and show the selected 'Static Text Value' all the time."}),
      property({label: 'Static Text Value', name: 'value', inputType: 'text', limit: 256, toggledBy: 'static', hint: "When 'Static Text' is checked, use this value. Limited to 256 characters." }),
      property({label: 'Decimal Places', name: 'decimalPlaces', inputType: 'number', defaultValue: '-1', hint: 'Number of decimal places to display when value is a number. Defaults to -1 (unlimited).' }),
      property({label: 'Show Icon', name: 'showIcon', inputType: 'checkbox', defaultValue: false, hint: "When checked, show an icon with the value."}),
      property({label: 'Icon', name: 'icon', inputType: 'search', toggledBy: 'showIcon', data: ICON_SEARCH, hint: "Show this icon next to the value." }),
      property({label: 'Preformatted', name: 'preformatted', inputType: 'checkbox', defaultValue: false, hint: "Display text in a monospace font with \\n line breaks and spacing (leading and trailing spaces on lines) preserved."}),
    ],
    "publish": false,
    "descriptor": "Multiline Text"
  },
  "line_chart": {
    "max_feeds": 5,
    "min_size": [6, 4],
    "default_size": [6, 4],
    "max_size": [20, 20],
    "aspect_ratio": "dynamic",
    "description": "The line chart is used to graph one or more feeds.",
    "properties": [
      property({label: "Show History", name: 'historyHours', inputType: 'select', defaultValue: '24',
                select: [
                  ['Live (no history)', '0'],
                  ['1 hour',            '1'],
                  ['2 hours',           '2'],
                  ['4 hours',           '4'],
                  ['8 hours',           '8'],
                  ['24 hours',         '24'],
                  ['2 days',           '48'],
                  ['7 days',          '168'],
                  ['30 days',         '720'],
                  ['60 days',         '1440'],
                ]}),
      property({label: "X-Axis Label", name: 'xAxisLabel', inputType: 'text', defaultValue: 'X' }),
      property({label: "Y-Axis Label", name: 'yAxisLabel', inputType: 'text', defaultValue: 'Y' }),
      property({label: "Y-Axis Minimum", name: 'yAxisMin', inputType: 'text', defaultValue: '', hint: 'Leave blank to automatically detect.' }),
      property({label: "Y-Axis Maximum", name: 'yAxisMax', inputType: 'text', defaultValue: '', hint: 'Leave blank to automatically detect.' }),
      property({label: "Y-Axis Scale", name: 'yAxisScale', inputType: 'select', defaultValue: 'linear', hint: 'Linear is the default.',
          select: [
            ['Linear',      'linear'],
            ['Logarithmic', 'logarithmic'],
          ]}),
      property({label: "Decimal Places", name: 'decimalPlaces', inputType: 'number', defaultValue: '4', hint: 'Number of decimal places to display, defaults to 4.' }),
      property({label: "Raw Data Only", name: 'rawDataOnly', inputType: 'checkbox', defaultValue: false, hint: "When checked, do not show aggregate data ever. If the chart history includes more than 640 data points, only the 640 most recent will be shown."}),
      property({label: "Stepped Line", name: 'steppedLine', inputType: 'checkbox', defaultValue: false, hint: "Use a stepped line graph. Useful for representing logic levels."}),
      property({label: "Draw Grid Lines", name: 'gridLines', inputType: 'checkbox', defaultValue: false, hint: "When checked, the x and y axis grid lines will be drawn."}),
      property({label: "Feed Key Legend", name: 'feedKeyLegend', inputType: 'checkbox', defaultValue: false, hint: "Use your feed key as the label, if your feed is in a group, it will be included. Example: kitchen.temperature"}),
    ],
    "descriptor": "Line Chart"
  },
  "color_picker": {
    "max_feeds": 1,
    "min_size": [4, 4],
    "default_size": [4, 4],
    "max_size": [20, 20],
    "aspect_ratio": "fixed",
    "description": "The color picker is used to send or view color values in hex format.",
    "properties": [],
    "publish": true,
    "descriptor": "Color Picker"
  },
  "map": {
    "max_feeds": 1,
    "min_size": [4, 4],
    "default_size": [4, 4],
    "max_size": [20, 20],
    "aspect_ratio": "dynamic",
    "description": "A map can track the locations of your feed data.",
    "properties": [
      property({label: "Hours of History (0 for realtime)", name: 'historyHours', inputType: 'text'}),
      property({label: "Light Mode Map Type", name: 'tileLight', inputType: 'select', defaultValue: 'street', select: [['Street Map', 'street'], ['Satellite Imagery', 'sat']] }),
      property({label: "Dark Mode Map Type", name: 'tile', inputType: 'select', defaultValue: 'street', select: [['Street Map', 'street'], ['Satellite Imagery', 'sat']] }),
    ],
    "descriptor": "Map"
  },
  "stream": {
    "max_feeds": 25,
    "min_size": [4, 4],
    "default_size": [4, 4],
    "max_size": [20, 20],
    "aspect_ratio": "dynamic",
    "description": "A stream block can be used to view the rolling history of data for multiple feeds. Up to 5 of your feeds can send data to this block.",
    "properties": [
      property({label: "Hours of History (0 for realtime)", name: 'historyHours', inputType: 'text'}),
      property({label: "Font Size", name: 'fontSize', inputType: 'select', defaultValue: '12', select: [['Small', '12'], ['Medium', '16'], ['Large', '22']] }),
      property({label: "Colorscheme", name: 'fontColor', inputType: 'select', defaultValue: '#63de00',
        select: [
          ['Green', '#63de00'],
          ['White', '#ffffff'],
          ['Greenscale', 'greenscale'],
          ['Grayscale', 'grayscale'],
          ['Bluescale', 'bluescale'],
          ['Fairyfloss', 'fairyfloss'],
        ]
      }),
      property({label: "Show Group Name?", name: 'showGroupName', inputType: 'select', defaultValue: 'yes', select: [['Yes', 'yes'], ['No', 'no']] }),
      property({label: "Show Feed Name?", name: 'showName', inputType: 'select', defaultValue: 'yes', select: [['Yes', 'yes'], ['No', 'no']] }),
      property({label: "Show Timestamp?", name: 'showTimestamp', inputType: 'select', defaultValue: 'yes', select: [['Yes', 'yes'], ['No', 'no']] }),
      property({label: "Show Location?", name: 'showLocation', inputType: 'select', defaultValue: 'no', select: [['No', 'no'], ['Yes', 'yes']] }),
      property({label: "Show Errors?", name: 'errors', inputType: 'select', defaultValue: 'yes', select: [['Yes', 'yes'], ['No', 'no']] }),
      // property("HISTORY (PER FEED)", 'history', 'select', '1', [['Last 1', '1'], ['Last 5', '5'], ['Last 10', '10']]),
    ],
    "descriptor": "Stream"
  },
  "image": {
    "max_feeds": 1,
    "min_size": [1, 1],
    "default_size": [6, 4],
    "max_size": [20, 20],
    "aspect_ratio": "dynamic",
    "description": "An image block can be used to view base64 encoded images. Drag and drop images onto the block to publish them back to the feed.",
    "properties": [],
    "publish": true,
    "descriptor": "Image"
  },
  "dashboard_link": {
    "max_feeds": 0,
    "min_size": [2, 1],
    "default_size": [2, 1],
    "max_size": [20, 20],
    "aspect_ratio": "dynamic",
    "description": "Link to other Dashboards",
    "properties": [
      property({label: "Dashboards", name: 'dashboards' , inputType: 'dashboards',
                multi: true, dataSource: 'dashboards', defaultValue: [], hint: 'Dashboard links will appear in the order they are selected.' }),
    ],
    "publish": true,
    "descriptor": "Dashboard Link"
  },
}

export default BlockTypes

function ensureDefaultProperties(blockType, properties) {
  // use given or set as empty object if null or undefined
  let props = {}
  if (properties) {
    props = Object.assign({}, properties)
  }

  const block = BlockTypes[blockType]

  if (block) {
    // set all default values from definition
    block.properties.forEach(prop => {
      if (!propCheck(props, prop.name)) {
        props[prop.name] = prop.defaultValue
      }
    })
  }

  return props
}

export { ensureDefaultProperties }

const BREAKPOINT_KEYS = ['xl', 'lg', 'md', 'sm', 'xs']
const BREAKPOINTS = {
  xl: 2200,
  lg: 1200,
  md: 768,
  sm: 480,
  xs: 0
}

const COLUMNS = {
  xl: 16,
  lg: 16,
  md: 12,
  sm: 8,
  xs: 1
}

const DashboardConfig = {
  BREAKPOINTS: BREAKPOINTS,
  BREAKPOINT_KEYS: BREAKPOINT_KEYS,
  COLUMNS: COLUMNS,

  getBreakpointFromWidth: (width) => {
    let key
    for (let i=0; i < BREAKPOINT_KEYS.length; i++) {
      key = BREAKPOINT_KEYS[i]

      if (width > BREAKPOINTS[key]) {
        return key
      }
    }
  }
}

export { DashboardConfig }
