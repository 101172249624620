// Concerning IO Client Subscriptions APIs (MQTT / Websocket), not io-rails' `Stream` model
//
// See actions/feeds for feed data loading via the HTTP API.
//

import { SubscriptionsManager, feedTopic, dashboardTopic, deviceTopic, publicDashboardTopic,
         errorsTopic, throttlesTopic, clientsTopic, wprsnprsActionTopic,
         wprsnprsDeviceActionTopic, wprsnprComponentActionTopic, wprsnprI2CComponentActionTopic } from 'utils/streaming'
import { buildAPIActions } from 'utils/actions'
import log from 'utils/logger' // eslint-disable-line
import nextID from 'utils/incrid'
import SystemMessageActions from './system_messages'
import { refreshDeviceIfCurrent, updateDeviceConnections } from 'slices/devices'
import WprSnprActions from './wprsnpr'


const TRACING = false

// generate a new message object for plain string messages recieved from
// SubscriptionsManager
const newMessage = message => ({
  id: nextID(),
  created_epoch: Date.now() * 0.001,
  value: message
})

// Only one SubscriptionsManager for the lifecycle of the App, all subscription
// traffic should go through Data Actions, so this is a safe place to put it
let _subscriptionsManagerInstance = null
function getSubscriptionsManager(dispatch, username=null, token=null) {
  if (!(username && token)) {
    return new SubscriptionsManager()
  }

  if (!_subscriptionsManagerInstance) {
    _subscriptionsManagerInstance = new SubscriptionsManager(username, token)
    _subscriptionsManagerInstance.on('connected', () => {
      dispatch({type: "SUBSCRIPTION_MANAGER_CONNECTED"})
    })

    _subscriptionsManagerInstance.on('reconnecting', () => {
      // since SubscriptionsManager automatically attempts reconnection, this
      // is the event we track to account for current connection state
      dispatch({type: "SUBSCRIPTION_MANAGER_DISCONNECTED"})
    })

    _subscriptionsManagerInstance.on('error', (err) => {
      console.error('SubscriptionsManager error', err)
      dispatch({type: "SUBSCRIPTION_MANAGER_ERROR", payload: err })
    })

    _subscriptionsManagerInstance.on('disconnected', () => {
      dispatch({type: "SUBSCRIPTION_MANAGER_CONNECTION_FAILED"})
    })
  }

  return _subscriptionsManagerInstance
}

// Just return the instance, don't worry about setting it up if it doesn't exist
function subscriptionsManagerInstance() {
  return _subscriptionsManagerInstance
}

function topicUnsubscribeAction(Subscriptions, topic, dispatch) {
  dispatch({
    type: 'UNSUBSCRIBE_FROM_TOPIC',
    payload: new Promise((resolve) => {
      Subscriptions.unsubscribe(topic, () => {
        resolve({ topic })
      })
    })
  })
}

function topicSubscribeAction(Subscriptions, topic, dispatch) {
  if (Subscriptions.isSubscribed(topic)) return

  dispatch({
    type: 'SUBSCRIBE_TO_TOPIC',
    payload: new Promise((resolve, reject) => {
      Subscriptions.subscribe(topic, {
        subscribed(topic) {
          // new subscription request has been sent
          resolve({ topic })
        },

        connectionError(ex) {
          reject(ex)
        },

        // handle inbound messages for a given subscription
        // NOTE: if messages come in VERY fast, it can overwhelm the browser
        message(messageRaw) {
          try {
            let message = JSON.parse(messageRaw)

            if (typeof message === 'string') {
              message = newMessage(message)
            }

            message.client_received_at = new Date()

            if (TRACING) log("SUBSCRIBE_TO_TOPIC dispatching for message on ", topic, message)

            const
              isThrottle = /\/throttle$/.test(topic),
              isError = /\/errors$/.test(topic),
              isWprsnprStatus = /\/wprsnpr\/status$/.test(topic),
              isWprsnprConnections = /\/wprsnpr\/connections$/.test(topic)

            if (isError) {
              // log('IS ERROR', message)
              dispatch(SystemMessageActions.replace('mqtt_error', 'alert', `MQTT ERROR: ${message.value}`))

            } else if (isThrottle) {
              // log('IS THROTTLE', message)
              dispatch(SystemMessageActions.replace('throttle_warning', 'warn', `THROTTLE WARNING: ${message.value}`))

            } else if (isWprsnprStatus) {
              // device/component refreshes
              const { refreshDeviceId } = message.wprsnpr

              if(refreshDeviceId) {
                dispatch(refreshDeviceIfCurrent(refreshDeviceId))
                return
              }

              // i2c stuff
              const { i2cBusScanResponse, i2cBusError } = message.wprsnpr

              if(i2cBusScanResponse){
                const { addressesFound } = i2cBusScanResponse
                dispatch(WprSnprActions.setI2cAddressesFound(addressesFound))
                return

              } else if(i2cBusError) {
                dispatch(WprSnprActions.i2cBusError(i2cBusError))
                return
              }

              // a new wippersnapper checkin is happening, set the machineName & deviceId
              const { machineName, deviceId, semver } = message.wprsnpr

              if(!machineName || !deviceId) {
                throw new Error(`WipperSnapper checkin without proper data: machineName ${machineName}, deviceId: ${deviceId}`)
              }

              dispatch(WprSnprActions.wprsnprCheckin({ machineName, deviceId, semver }))

            } else if (isWprsnprConnections) {
              // we receive this event when the browser first connects to the broker,
              // and whenever a wipper device connects or disconnects.
              // the browser payload includes events, the others do not
              const { connections, events } = message

              // this action knows how to deal with the presence/absence of events
              dispatch(updateDeviceConnections(connections, events))
            }

            dispatch(Data.subscriptionMessageReceived({ topic, message }))
          } catch (ex) {
            dispatch(Data.subscriptionMessageFailed({ topic, message: messageRaw.toString(), error: ex }))
          }
        }
      })
    })
  })
}

function _dataAction(action, topic) {
  return (dispatch, getState) => {
    const client = getState().session.client
    const Subscriptions = getSubscriptionsManager(dispatch, client.username, client.jwt)
    action(Subscriptions, topic, dispatch, getState)
  }
}

const Data = {
  // build all available API actions
  ...buildAPIActions('data'),

  disconnect: () => {
    return dispatch => {
      const Subscriptions = subscriptionsManagerInstance()
      if (Subscriptions) {
        Subscriptions.disconnect(() => {
          dispatch({type: 'DATA_SUBSCRIPTION_DISCONNECT_FULFILLED'})
        })
      } else {
        dispatch({type: 'DATA_SUBSCRIPTION_DISCONNECT_REJECTED', payload: 'No subscription manager present.'})
      }
    }
  },

  clearErrors: (username) => ({
    type: 'CLEAR_ERRORS_DATA',
    payload: { username }
  }),

  subscribeToDashboard: (username) => _dataAction(topicSubscribeAction, dashboardTopic(username)),
  subscribeToDevice: (username, device_id) => _dataAction(topicSubscribeAction, deviceTopic(username, device_id)),
  subscribeToPublicDashboard: (username) => _dataAction(topicSubscribeAction, publicDashboardTopic(username)),
  subscribeToErrors: (username) => _dataAction(topicSubscribeAction, errorsTopic(username)),
  subscribeToThrottle: (username) => _dataAction(topicSubscribeAction, throttlesTopic(username)),
  subscribeToFeed: (username, feedKey) => _dataAction(topicSubscribeAction, feedTopic(username, feedKey)),
  subscribeToClients: (username) => _dataAction(topicSubscribeAction, clientsTopic(username)),
  subscribeToWprsnprStatus: (username) => _dataAction(topicSubscribeAction, wprsnprsActionTopic(username, "status")),
  subscribeToWprsnprConnections: (username) => _dataAction(topicSubscribeAction, wprsnprsActionTopic(username, "connections")),

  unsubscribeFromDashboard: (username) => _dataAction(topicUnsubscribeAction, dashboardTopic(username)),
  unsubscribeFromDevice: (username, device_id) => _dataAction(topicUnsubscribeAction, deviceTopic(username, device_id)),
  unsubscribeFromPublicDashboard: (username) => _dataAction(topicUnsubscribeAction, publicDashboardTopic(username)),
  unsubscribeFromErrors: (username) => _dataAction(topicUnsubscribeAction, errorsTopic(username)),
  unsubscribeFromThrottle: (username) => _dataAction(topicUnsubscribeAction, throttlesTopic(username)),
  unsubscribeFromFeed: (username, feedKey) => _dataAction(topicUnsubscribeAction, feedTopic(username, feedKey)),
  unsubscribeFromClients: (username) => _dataAction(topicUnsubscribeAction, clientsTopic(username)),
  unsubscribeFromWprsnprStatus: (username) => _dataAction(topicUnsubscribeAction, wprsnprsActionTopic(username, "status")),
  unsubscribeFromWprsnprConnections: (username) => _dataAction(topicUnsubscribeAction, wprsnprsActionTopic(username, "connections")),

  publishToFeed: (username, feedKey, value) => {
    return (dispatch, getState) => {
      const
        topic = feedTopic(username, feedKey),
        state = getState(),
        client = state.session.client,
        Subscriptions = getSubscriptionsManager(dispatch, client.username, client.jwt),
        publishableValue = JSON.stringify({ value })

      Subscriptions.publish(topic, publishableValue, () => {
        dispatch({
          type: 'DATA_PUBLISHED_TO_FEED',
          payload: { topic, value: publishableValue }
        })
      })
    }
  },

  publishToWipperDeviceAction: (deviceId, action, actionArgs={}) => {
    return (dispatch, getState) => {
      const
        { username, jwt } = getState().session.client,
        Subscriptions = getSubscriptionsManager(dispatch, username, jwt),
        topic = wprsnprsDeviceActionTopic(username, deviceId, action),
        payload = JSON.stringify(actionArgs),
        emptyCallback = () => {}

      Subscriptions.publish(topic, payload, emptyCallback)
    }
  },

  publishToWipperComponentAction: (deviceId, componentKey, action, payload=null) => {
    return (dispatch, getState) => {
      const
        { username, jwt } = getState().session.client,
        Subscriptions = getSubscriptionsManager(dispatch, username, jwt),
        topic = wprsnprComponentActionTopic(username, deviceId, action),
        payloadJSON = payload
          ? JSON.stringify(payload)
          : JSON.stringify({ componentKey }),
        emptyCallback = () => {}

      Subscriptions.publish(topic, payloadJSON, emptyCallback)
    }
  },

  publishToWipperI2CComponentAction: (deviceId, action, payload) => {
    return (dispatch, getState) => {
      const
        { username, jwt } = getState().session.client,
        Subscriptions = getSubscriptionsManager(dispatch, username, jwt),
        topic = wprsnprI2CComponentActionTopic(username, deviceId, action),
        emptyCallback = () => {}

      Subscriptions.publish(topic, JSON.stringify(payload), emptyCallback)
    }
  },

  resetFeedSubscription: (username, feed) => ({
    type: 'DATA_RESET_FEED_SUBSCRIPTION',
    payload: { username, feed }
  }),

  subscriptionMessageReceived: (payload) => ({
    type: 'DATA_SUBSCRIPTION_MESSAGE_RECEIVED',
    payload
  }),

  subscriptionMessageFailed: (payload) => ({
    type: 'DATA_SUBSCRIPTION_MESSAGE_FAILED',
    payload
  }),

  updateFeedTableOptions: (feed, options) => ({
    type: 'DATA_UPDATE_FEED_TABLE_OPTIONS',
    payload: { feed, options }
  })
}

export default Data
