import Cookies from 'universal-cookie'
import { createActions } from 'redux-actions'
import queryString from 'query-string'
import 'whatwg-fetch'

import { Paths } from 'utils/paths'
import { setBoards } from 'slices/boards'
import { setComponentTypes } from 'slices/component_types'
import DataActions from 'actions/data'
import BrowsingActions from 'actions/browsing'
import KeysActions from 'actions/keys'
import Navigation from 'utils/navigation'
import log from 'utils/logger' // eslint-disable-line
import logException from 'utils/errors'
import { ioFetch } from 'utils/ajax'

// using sessionStorage will result in per-tab sessions
// localStorage will result in per-browser sessicookiesons
const StorageEngine = localStorage

const cookies = new Cookies();

const Session = createActions({
  SET_SESSION_TOKEN(token) {
    StorageEngine?.setItem('token', token);
    return token
  },

  SET_SESSION_USER(user) {
    StorageEngine?.setItem('user', JSON.stringify(user));
    return user
  },

  REMOVE_USER_SESSION() {
    try {
      StorageEngine?.clear()
    } catch (ex) {
      log("error clearing storage engine")
    }
    cookies.remove('logged_in');
    cookies.remove('token');

    return null
  },
},
  // identity actions (return argument as payload)
  'SET_SESSION_CLIENT',
  'SESSION_INIT',
  'SESSION_INIT_DONE',
  'SET_SESSION_ERROR',
  'SESSION_NOT_READY_ERROR',
  'CLIENT_NOT_READY_ERROR',
  'CLIENT_NOT_AUTHENTICATED_ERROR'
)

// thunked action creators aren't compatible with createActions
Session.initializeSession = function () {
  return (dispatch, getState) => {
    if (window.location.search) {
      const parsed = queryString.parse(window.location.search)
      if (parsed["io-key"] && parsed["io-key"].toLowerCase() === 'true') {
        dispatch(KeysActions.toggleModal(true))
      }
    }

    const { session } = getState()

    // debounce
    const { loading, ready } = session;
    if (loading || ready) return;

    // signal init
    dispatch(Session.sessionInit())

    let token
    // check cookies for a token from accounts
    if (cookies.get('token')) {
      token = cookies.get('token')
    // check local store for a locally persisted token
    } else if (StorageEngine?.getItem('token')) {
      token = StorageEngine.getItem('token')
    }

    cookies.remove('token')

    if (token) { dispatch(Session.setSessionToken(token)) }

    dispatch(Session.initializeClient())
  }
}

Session.initializeClient = function () {
  return (dispatch, getState) => {
    const { session } = getState()
    const { client, token } = session

    if (client) {
      // client is already in session

      if (client.jwt) {
        // ... and already authenticated
        dispatch(Session.loadUser())
        return
      }

      if (! token) {
        // start anonymous session
        dispatch(Session.sessionInitDone())
        return
      } else {
        dispatch(Session.setSessionClient({ ...client, jwt: token }))
        dispatch(Session.loadUser())
        return
      }

    } else {
      // no client available, initialize
      const client = {
        ssl: import.meta.env.VITE_SSL,
        host: import.meta.env.VITE_IO_HOST,
        enableCookies: true,
        jwt: token
      }

      dispatch(Session.setSessionClient(client))

      if (client.jwt) {
        dispatch(Session.loadUser()) // will trigger sessionInitDone
      } else {
        dispatch(Session.sessionInitDone())
      }
    }
  }
}

Session.loadUser = function () {
  return (dispatch, getState) => {
    // reusable error handler func
    const handleUserError = err => {
      if (isExpiredSignatureError(err) || isRevokedTokenError(err)) {
        // a parsable swagger error referring to an expired token, force re-authentication
        log("failed to get current user", err)
        Navigation.go(Paths.sign_out())

      } else {
        // otherwise something unknown is wrong. tell sentry
        logException(err)
        // remove the session from this client so we don't get stuck in a loop
        dispatch(Session.removeUserSession())

        // brief delay before redirect so the Sentry gets out
        setTimeout(() => {
          // redirect to accounts, remembering this page
          const redir = encodeURIComponent(location.pathname)
          // go to accounts to get a token, then redirect here
          location.href = `${import.meta.env.VITE_IO_SIGN_IN_URL}?redirect_to=${redir}`
        }, 50)
      }
    }

    const { session: { client }} = getState()

    let userPromise

    try {
      // Session.ajax throws synchronously
      userPromise = Session.ajax(
        client,
        Paths.api_user(),
        { credentials: 'include' }
      )
    } catch(err) { // catches if .ajax throws
      handleUserError(err)
      dispatch(Session.sessionInitDone())
      return
    }

    userPromise.then(userSession => {
      window.client = client

      if (!userSession) {
        // could not load user
        log("could not get valid user from client.users.get()")
        return
      }

      const { user, profile, sidebar, navigation, system_messages,
        throttle, wipper_semver, wipper_boards, wipper_components } = userSession

      window.user = user
      dispatch(Session.setSessionUser(user))
      dispatch({
        type: 'PROFILE_LOAD_FULFILLED',
        payload: profile
      })
      dispatch({
        type: 'SIDEBAR_LOAD_FULFILLED',
        payload: sidebar
      })
      dispatch({
        type: 'NAVIGATION_LOAD_FULFILLED',
        payload: navigation
      })

      system_messages && dispatch({
        type: 'SYSTEM_MESSAGES_LOAD_FULFILLED',
        payload: system_messages
      })

      throttle && dispatch({
        type: 'THROTTLE_LOAD_FULFILLED',
        payload: throttle
      })

      dispatch({
        type: 'WPRSNPR_SEMVER_LOAD_FULFILLED',
        payload: wipper_semver
      })
      dispatch(setBoards(wipper_boards))
      dispatch(setComponentTypes(wipper_components))

      if (user.username?.length) {
        // immediately and permanently subscribe to errors and generic dashboard messages
        dispatch(DataActions.subscribeToErrors(user.username))
        dispatch(DataActions.subscribeToThrottle(user.username))
        dispatch(DataActions.subscribeToDashboard(user.username))
      }
    }).catch(handleUserError) // catches if .then throws
    .finally(() => dispatch(Session.sessionInitDone()))
  }
}

Session.logout = function () {
  return (dispatch, getState) => {
    const
      client = Session.getAuthenticatedClient(dispatch, getState),
      logoutFrontend = () => {
        dispatch(DataActions.disconnect())
        dispatch(Session.removeUserSession())
      }

    // can't log out of backend without a client, do frontend and early out
    if (!client) { logoutFrontend(); return }

    // log out of backend
    Session.ajax(
      client,
      `${import.meta.env.VITE_IO_URL}/api/v2/sessions`,
      { method: 'DELETE', credentials: 'include' }

    ).catch( ex => {
      console.error("ERROR in log out:", ex)

    // log out of frontend regardless of how backend goes
    }).finally(logoutFrontend)
  }
}

// Wrap fetch, including JWT in headers, with generic error status catching and
// JSON response unpacking. ALL responses from requests sent by this method
// MUST be JSON.
Session.ajax = function (client, url, options={}) {
  if (!client) { throw 'client required for Session.ajax' }

  const
    defaultOptions = { method: 'GET' },
    defaultHeaders = { 'Content-Type': 'application/json' }

  if (client.jwt) {
    defaultHeaders['Authorization'] = `Bearer ${client.jwt}`
  }

  let headers = Object.assign(defaultHeaders, (options.headers || {}))

  if (options.headers &&
      Object.prototype.hasOwnProperty.call(options.headers, 'Content-Type') &&
      options.headers['Content-Type'] === null) {
    delete headers['Content-Type']
    delete defaultHeaders['Content-Type']
    delete options.headers['Content-Type']
  }

  const includeHeaders = !!options['includeHeaders']
  if (typeof options['includeHeaders'] !== 'undefined') {
    delete options['includeHeaders']
  }

  const opts = Object.assign({}, defaultOptions, options, { headers })

  return ioFetch(url, opts, { includeHeaders })
}

// Client retrieval helpers for other actions. For example, in actions/feeds.js:
//
//   import Session from './session'
//
//   Feeds.something = () => {
//      return (dispatch, getState) => {
//        const client = Session.getAuthenticatedClient(dispatch, getState)
//
//        ... do something here
//      }
//   }

// when you don't care whether the API client is authenticated
Session.getClient = (dispatch, getState) => {
  const { session } = getState()
  if (! session) {
    dispatch(Session.sessionNotReadyError())
    return null
  }

  const { client } = session
  if (! client) {
    dispatch(Session.clientNotReadyError())
    return null
  }

  return client
}

// when you do care whether the API client is authenticated
Session.getAuthenticatedClient = (dispatch, getState) => {
  const { session } = getState()
  if (! session) {
    dispatch(Session.sessionNotReadyError())
    return null
  }

  const { client } = session
  if (! client) {
    dispatch(Session.clientNotReadyError())
    return null
  }

  if (! client.username) {
    dispatch(Session.clientNotAuthenticatedError())
    return null
  }

  return client
}

//// helper functions for use within other actions

// test Swagger API response
function isExpiredSignatureError(err) {
  return err &&
    err.obj &&
    err.obj.error &&
    /expired sig/.test(err.obj.error);
}

function isRevokedTokenError(err) {
  return err &&
    err.obj &&
    err.obj.error &&
    /revoked/.test(err.obj.error);
}


export default Session
